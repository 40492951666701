<template>
  <div class="home">
    <div v-if="screenWidth > 910" class="block">
      <el-carousel trigger="click" indicator-position="none" id="el-carousel" style="width:100%;">
        <el-carousel-item v-for="(item,index) in pics" :key="index">
          <a :href="item.url"><img :src="item.src" style="width:100%;"/></a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else class="block">
      <el-carousel trigger="click" indicator-position="none" id="el-carousel" style="width:100%;">
        <el-carousel-item v-for="(item,index) in phonePics" :key="index">
          <a :href="item.url"><img :src="item.src" style="width:100%;"/></a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="screenWidth > 910" class="intro1">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content"><span>新闻中心</span><br><a href="http://jfkbio.com/list"><img src="../assets/index/1.jpg" /></a></div></el-col>
        <el-col :span="6"><div class="grid-content"><span>公司介绍</span><br><a href="http://jfkbio.com/article?id=152"><img src="../assets/index/2.jpg" /></a></div></el-col>
        <el-col :span="6"><div class="grid-content"><span>菲佑宁®</span><br><a href="http://jfkbio.com/article?id=162"><img src="../assets/index/3.jpg" /></a></div></el-col>
        <el-col :span="6"><div class="grid-content"><span>菲长乐®</span><br><a href="http://jfkbio.com/article?id=165"><img src="../assets/index/4.jpg" /></a></div></el-col>
      </el-row> 
    </div>
    <div v-else class="intro2">
      <el-row :span="24">
        <el-col :span="24"><div class="grid-content"><span>新闻中心</span><a href="http://jfkbio.com/list"><img src="../assets/index/1.jpg" /></a></div></el-col>
      </el-row> 
      <el-row :span="24">
        <el-col :span="24"><div class="grid-content"><span>公司介绍</span><a href="http://jfkbio.com/article?id=152"><img src="../assets/index/2.jpg" /></a></div></el-col>
      </el-row> 
      <el-row :span="24">
        <el-col :span="24"><div class="grid-content"><span>菲佑宁®</span><a href="http://jfkbio.com/article?id=162"><img src="../assets/index/3.jpg" /></a></div></el-col>
      </el-row> 
      <el-row :span="24">
        <el-col :span="24"><div class="grid-content"><span>菲长乐®</span><a href="http://jfkbio.com/article?id=165"><img src="../assets/index/4.jpg" /></a></div></el-col>
      </el-row> 
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pics: [
        { src: '../images/index/101.jpg', url: 'http://jfkbio.com/article?id=8'},
        { src: '../images/index/102.jpg', url: 'http://jfkbio.com/article?id=1'},
        { src: '../images/index/103.jpg', url: 'http://jfkbio.com/article?id=38'},
        { src: '../images/index/104.jpg', url: 'http://jfkbio.com/article?id=7'}
      ],
      phonePics: [
        { src: '../images/index/fcl.jpg', url: 'http://jfkbio.com/article?id=151'},
        { src: '../images/index/hhg.jpg', url: 'http://jfkbio.com/article?id=150'}
      ],
      bannerHeight: 500,
      screenWidth: 0
    }
  },
  mounted() {
      this.setSize1();
      const that = this;
      // window.onresize = () => {
      //   return (() => {
      //     window.screenWidth = document.body.clientWidth;
      //     that.screenWidth = window.screenWidth;
      //     that.setSize();
      //   })()
      // }
     
      // window.addEventListener('resize', function() {
      //   var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      //   that.screenWidth = width;
      //   that.setSize();
      // }, false);
      that.screenWidth = document.body.clientWidth
      console.log(document.body)
  },
  methods: {
      setSize1: function() {
        //图片
        this.bannerHeight = 500 / 1130 * this.$store.state.screenWidth - 50
        //document.getElementById('el-carousel').style.height = this.bannerHeight + 'px';
      }
  }

}
</script>
<style scoped>
.home {
  width: 100%;
  margin: 15px auto;
  min-height: 738px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #fff;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #fff;
}
.intro1 {
  width: 80%;
  margin:30px 0;
  padding-bottom: 40px;
  margin-left: 10%;
}
.intro2 {
  width: 96%;
  margin:30px 0;
  padding-bottom: 10px;
  margin-left: 2%;
}
.intro1 img {
  width: 60%;
  margin-left: 3%;
}
.intro2 img {
  width: 90%;
}
.intro2 span{
  height: 40px;
  line-height: 40px;
}
.intro1 span {
  height: 60px;
  line-height: 60px;
  font-size: 20px;
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  text-align: center;
  border-radius: 4px;
  min-height: 36px;
}
</style>